<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col v-for="section in data" :key="section.id" lg="6" sm="6">
        <b-card>
          <div class="mb-1">
            <h4 class="mb-0">
              {{ section.name }}
            </h4>
          </div>
          <div class="mt-1">
            <b-row>
              <b-col cols="4">
                <b-card-text>
                  <h5>Lessons: {{ section.lessons_count }}</h5>
                </b-card-text>
              </b-col>
              <b-col cols="4">
                <b-card-text>
                  <h5>Exercises: {{ section.exercises_count }}</h5>
                </b-card-text>
              </b-col>
            </b-row>
          </div>
          <div class="mt-1">
            <b-button variant="outline-warning" :to="{name: 'teacher-lessons-view', params: { chapter_id: section.chapter.id, section_id: section.id}}">View Lessons</b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BButton, BCardText } from "bootstrap-vue";
import router from "@/router";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
  },
  data() {
    return {
      data: [],
    };
  },
  async mounted() {
    await this.$http
      .get(
        `admin/teacher/chapter/${router.currentRoute.params.chapter_id}/sections`
      )
      .then((response) => {
        this.data = response.data.data;
      });
  },
};
</script>
